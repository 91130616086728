$input-x : 1rem;
$input-y : .6125rem;
$input-border-r: .3125rem;

$input-padding-y: $input-y;
$input-padding-x: $input-x;

$input-padding-y-sm: $input-y;
$input-padding-x-sm: $input-x;

$input-padding-y-lg: $input-y;
$input-padding-x-lg: $input-x;

$input-font-size: 1rem;
$input-font-size-sm: .875rem;
$input-font-size-lg:  $input-font-size;

$input-border-color: var(--#{$prefix}gray-300);

$input-border-radius: $input-border-r;
$input-border-radius-sm: $input-border-r;
$input-border-radius-lg: $input-border-r;

$input-focus-border-color: $input-border-color;
$input-focus-box-shadow: 0px 0px 15px 0px rgba(187, 187, 187, 0.5);

$input-placeholder-color: var(--#{$prefix}gray-500);


$form-file-button-bg: $white;
$form-file-button-hover-bg: #F2F2F2;