$form-select-padding-y: .6125rem;
$form-select-padding-y-sm: .5rem;
$form-select-padding-y-lg: .75rem;

$form-select-font-size: 1rem;
$form-select-font-size-sm: .875rem;
$form-select-font-size-lg: $form-select-font-size;

$form-select-border-color: $input-border-color;

$form-select-border-radius: $input-border-r;
$form-select-border-radius-sm: $input-border-r;
$form-select-border-radius-lg: $input-border-r;

$form-select-focus-border-color: $input-border-color;
$form-select-focus-box-shadow: $input-focus-box-shadow;